<template>
  <div>
    <nav class="navbar">
      <NavBar />
    </nav>
    <v-container>
      <h1 class="text-center mb-6">Welcome to Our Book Store</h1>

      <!-- Loading State -->
      <v-progress-circular
        v-if="loading"
        indeterminate
        color="primary"
        class="d-flex mx-auto"
      ></v-progress-circular>

      <!-- Book Display Section -->
      <v-row v-else>
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="3"
          v-for="book in books"
          :key="book.id"
        >
          <v-card class="book-card" elevation="5">
            <v-img
              :src="book.imageUrl"
              height="200"
              object-fit="contain"
              class="book-image"
            ></v-img>

            <v-card-title class="text-h6">{{ book.title }}</v-card-title>
            <v-card-subtitle>By {{ book.author }}</v-card-subtitle>

            <v-card-text>
              <div class="my-2"><strong>Price:</strong> ${{ book.price }}</div>
              <div class="my-2"><strong>Genre:</strong> {{ book.genre }}</div>
              <div class="book-description">
                {{ truncateDescription(book.description) }}
              </div>
            </v-card-text>

            <v-card-actions>
              <v-btn
                color="primary"
                variant="outlined"
                @click="openBookDetails(book)"
              >
                View Details
              </v-btn>
              <v-btn color="success" @click="addToCart(book)"
                >Add to Cart</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <!-- Empty State -->
      <v-alert v-if="!loading && books.length === 0" type="info" class="mt-4">
        No books available at the moment. Please check back later.
      </v-alert>

      <!-- Modal for displaying book details -->
      <v-dialog v-model="dialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">{{ selectedBook?.title }}</span>
          </v-card-title>
          <v-card-subtitle>By {{ selectedBook?.author }}</v-card-subtitle>
          <v-card-text>
            <div><strong>Price:</strong> ${{ selectedBook?.price }}</div>
            <div><strong>Genre:</strong> {{ selectedBook?.genre }}</div>
            <div><strong>Description:</strong></div>
            <div>{{ selectedBook?.description }}</div>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="dialog = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import { mapState, mapActions } from "vuex";
import NavBar from "../components/NavBar.vue";

export default {
  name: "Home",
  components: { NavBar },
  data() {
    return {
      books: [],
      loading: true,
      error: null,
      dialog: false,
      selectedBook: null, // Track the selected book for the modal
    };
  },
  computed: {
    ...mapState(["isLoggedIn", "username"]),
  },
  methods: {
    ...mapActions(["clearUser"]),

    async fetchBooks() {
      this.loading = true;
      this.error = null;
      try {
        const response = await axios.get("/api/admin/search", {
          params: { title: "" },
        });

        if (response.data && Array.isArray(response.data)) {
          this.books = response.data;
        } else {
          throw new Error("Invalid book data format");
        }
      } catch (error) {
        console.error("Error fetching books:", error);
        this.error = "Failed to load books. Please try again later.";
        this.books = [];
      } finally {
        this.loading = false;
      }
    },

    truncateDescription(description) {
      return description?.length > 100
        ? `${description.substring(0, 100)}...`
        : description || "";
    },

    async addToCart(book) {
      if (!this.isLoggedIn) {
        alert("You need to log in to add books to the cart.");
        this.$router.push("/login");
        return;
      }

      try {
        const response = await axios.post("/api/cart/add", {
          username: this.username,
          bookId: book.id,
        });
        alert("Book added to cart successfully!");
        console.log("Add to cart response:", response);
      } catch (error) {
        console.error("Error adding to cart:", error.response);
        alert("Failed to add book to cart. Please try again.");
      }
    },

    openBookDetails(book) {
      this.selectedBook = book;
      this.dialog = true;
    },
  },
  mounted() {
    this.fetchBooks();
  },
};
</script>
message.txt 5 KB
