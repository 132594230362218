<template>
  <nav class="navbar">
    <router-link to="/">Home</router-link>
    <router-link to="/about">About</router-link>
    <router-link v-if="!isLoggedIn" to="/login">Login</router-link>
    <button v-if="isLoggedIn" @click="logout">Logout</button>
    <router-link v-if="isLoggedIn && !isAdmin" to="/profile"
      >Profile</router-link
    >
    <router-link v-if="isAdmin" to="/admin/dashboard"
      >Admin Dashboard</router-link
    >
    <!-- Added cart icon with badge -->
    <router-link v-if="!isAdmin" to="/cart" class="cart-icon">
      <v-icon>mdi-cart</v-icon>
      <span v-if="cartItemCount > 0" class="cart-badge">{{
        cartItemCount
      }}</span>
    </router-link>
  </nav>
</template>

<style scoped>
.navbar {
  display: flex;
  gap: 20px;
  padding: 10px;
  background: #333;
}
.navbar a,
.navbar button {
  color: white;
  text-decoration: none;
  padding: 5px 10px;
  background: transparent;
  border: none;
  cursor: pointer;
}
.navbar a:hover,
.navbar button:hover {
  background: #555;
}

/* Cart icon styles */
.cart-icon {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: auto; /* Pushes cart to the right */
  padding: 5px 10px;
}

.cart-badge {
  position: absolute;
  top: -5px;
  right: -5px;
  background: #ff5252;
  color: white;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

<script>
import axios from "axios";
import { mapState, mapActions } from "vuex";

export default {
  name: "NavbarComponent",
  computed: {
    ...mapState(["isLoggedIn", "role"]),
    isAdmin() {
      return this.role === "admin";
    },
    isUser() {
      return this.role === "user";
    },
  },
  methods: {
    ...mapActions(["clearUser"]),
    async logout() {
      try {
        await axios.get("/api/logout"); // Clear the session on the server
        this.clearUser(); // Clear the user state in Vuex
        this.$router.push("/login"); // Redirect to the login page
      } catch (error) {
        console.error("Logout failed:", error);
      }
    },
  },
};
</script>
