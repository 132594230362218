import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import axios from "axios";
import VueAxios from "vue-axios";
import "vuetify/styles";
import "@mdi/font/css/materialdesignicons.css";

loadFonts();

// Create the app instance
const app = createApp(App);

// Use plugins with the app instance
app
  .use(router)
  .use(store)
  .use(vuetify)
  .use(VueAxios, axios) // Use axios with VueAxios plugin
  .mount("#app");
