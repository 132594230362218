import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../views/Home.vue";
import store from "@/store";
import axios from "axios";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/login",
    name: "Login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Login.vue"),
  },
  {
    path: "/signup",
    name: "Signup",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Signup.vue"),
  },
  {
    path: "/admin/dashboard",
    name: "AdminDashboard",
    component: () =>
      import(
        /* webpackChunkName: "adminDashboard" */ "../views/AdminDashboard.vue"
      ),
    meta: { requiresAdmin: true },
  },
  {
    path: "/admin/add-book",
    name: "AddBook",
    component: () =>
      import(/* webpackChunkName: "addBook" */ "../views/AddBook.vue"),
    meta: { requiresAdmin: true },
  },
  {
    path: "/cart", // Added route for cart
    name: "Cart",
    component: () => import(/* webpackChunkName: "cart" */ "../views/Cart.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/profile",
    name: "UserProfile",
    component: () => import("../views/UserProfile.vue"),
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

// Setup beforeEach hoook
router.beforeEach(async (to, from, next) => {
  // get login state using whomai and axios
  let response = await axios.get("/api/whoami");
  // response.data is our payload
  // vuex store dispatch is asynchronous so we need to await it
  await store.dispatch("setLoggedInUser", response.data);
  let isLoggedIn = store.state.isLoggedIn;
  let isAdmin = store.state.isAdmin;

  // make sure if user is logged, user will not be able to see login page
  if (to.name === "Login" && isLoggedIn) {
    if (isAdmin) {
      next({ name: "AdminDashboard" }); // Redirect admin to dashboard
    } else {
      next({ name: "Home" }); // Redirect regular user to home
    }
    return;
  }
  if (to.meta.requiresAdmin && !isAdmin) {
    next({ name: "Login" }); // Admin only route, so regular users are redirected to login
    return;
  }
  if (to.name !== "Login" && to.name !== "Signup" && !isLoggedIn) {
    next({ name: "Login" });
  } else {
    next();
  }
});

export default router;
